import React, { useState, useContext } from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'

import { AnimationContext, CameraContext } from './Context'

export default function Navbar() {
    const [navbarOpen, setNavbarOpen] = useState(false)

    const handleNavbarOpen = () => {
        setNavbarOpen(navbarOpen => !navbarOpen)
    }

    const { setAnimation } = useContext(AnimationContext)

    const { setCameraPosition } = useContext(CameraContext) //default {x: 0, y: 2.8, z: -4.8}

    //spiral, circle
    const handleOnClick_Circle = () => {
        setAnimation('circle')
        setCameraPosition({ x: 1, y: 3.8, z: -4.8 })
    }

    const handleOnClick_Spiral = () => {
        setAnimation('spiral')
        setCameraPosition({ x: 8, y: 15, z: -10 })
    }

    const handleOnClick_Reset = () => {
        setAnimation('circle')
        setCameraPosition({ x: 0, y: 2.8, z: -4.8 })
    }

    return (
        <>
            <nav className='w-full fixed top-0 left-0 z-50 m-0 p-0'>
                <div className='navbar w-full h-[20vh] flex items-center justify-between mx-auto px-2 py-2'>
                    <div className='mx-3 md:mx-6 my-2 md:my-3.5 overflow-y-hidden p-1'>

                        <Link to={'/'}>
                            <motion.h1 className='italic text-white text-5xl md:text-8xl p-1'
                                initial={{ y: 150 }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 50, delay: 1, duration: 2 }}
                                onClick={handleOnClick_Reset} //reset to default
                            >Profoli&#127765;
                            </motion.h1>
                        </Link>

                    </div>

                    <div
                        className={'burger mx-3 md:mx-6 my-2 md:my-3.5 p-1 cursor-pointer ' + (navbarOpen === true ? '' : 'rounded border-2 border-solid border-white md:border-transparent')}
                        onClick={handleNavbarOpen}>
                        <div className={'w-8 h-1 bg-white m-1 ease-in-out duration-300 ' + (navbarOpen === true ? 'rotate-[45deg] translate-y-2' : 'md:w-12')}></div>
                        <div className={'w-8 h-1 bg-white m-1 ease-in-out duration-300 ' + (navbarOpen === true ? 'opacity-0' : 'opacity-100')}></div>
                        <div className={'w-8 h-1 bg-white m-1 ease-in-out duration-300 ' + (navbarOpen === true ? 'rotate-[-45deg] translate-y-[-0.5rem]' : '')}></div>
                    </div>
                </div>

                {navbarOpen &&
                    <div
                        className='navBody w-full h-[80vh] flex flex-col items-center justify-center mx-auto bg-[rgba(0,0,0,0.5)] px-2.5 md:px-5'
                        onClick={handleNavbarOpen}>

                        <motion.h1 className='text-white text-5xl p-1 md:p-2 md:text-8xl mx-2 my-3 md:my-5 hover:bg-clip-text hover:bg-gradient-to-r hover:from-red-500 hover:to-orange-500 hover:text-transparent'
                            initial={{ x: 100 }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 200, duration: 1 }}
                            onClick={handleOnClick_Circle}
                        >About</motion.h1>

                        <motion.h1 className='text-white text-5xl p-1 md:p-2 md:text-8xl mx-2 my-3 md:my-5 hover:bg-clip-text hover:bg-gradient-to-r hover:from-red-500 hover:to-orange-500 hover:text-transparent'
                            initial={{ x: -100 }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 200, duration: 1 }}
                            onClick={handleOnClick_Spiral}
                        >Projects</motion.h1>

                        <motion.h1 className='text-white text-5xl p-1 md:p-2 md:text-8xl mx-2 my-3 md:my-5 hover:bg-clip-text hover:bg-gradient-to-r hover:from-red-500 hover:to-orange-500 hover:text-transparent'
                            initial={{ x: 100 }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 200, duration: 1 }}
                            onClick={handleOnClick_Spiral}
                        >Contact</motion.h1>

                        <motion.h1 className='italic text-white text-5xl md:text-8xl mx-2 my-3 md:my-5 absolute bottom-0 right-0 px-3 md:px-6 py-1 hover:text-amber-300'
                            initial={{ scaleX: 0.5 }} animate={{ scaleX: 1 }} transition={{ type: "spring", stiffness: 100, duration: 1 }}
                        >Tom Fan</motion.h1>
                    </div>
                }
            </nav>
        </>
    )
}
