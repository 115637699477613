import React from 'react'
import Navbar from './Navbar'
import NewCanvas from './NewCanvas'

export default function Main() {
    return (
        <>
            <div className='w-full h-[100vh]'>
                <Navbar />
                <NewCanvas />
            </div>
        </>
    )
}
