import React, { Suspense, useEffect, useRef, useState, useMemo, useContext } from 'react'
import { Canvas, extend, useLoader, useFrame } from '@react-three/fiber'
import { Html, PerspectiveCamera, OrbitControls, MeshReflectorMaterial, CubeCamera, Effects, Environment, Stars, useContextBridge } from '@react-three/drei'
import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/three"
import { LinearEncoding, RepeatWrapping, TextureLoader, Vector3 } from 'three'
import * as THREE from 'three'
import { GLTFLoader, UnrealBloomPass } from 'three-stdlib'
import { motion } from "framer-motion"

import { AnimationContext, CameraContext } from './Context'

extend({ UnrealBloomPass })

//https://gracious-keller-98ef35.netlify.app/docs/
//https://polyhaven.com/

export default function NewCanvas() {

    const ContextBridge = useContextBridge(AnimationContext, CameraContext)

    return (
        <>
            <Suspense fallback={null}>
                <Canvas>
                    {/*useContext cannot pass into canvas component, ContextBridge is needed*/}
                    <ContextBridge>
                        <CanvasBody />
                    </ContextBridge>
                    <color args={[0, 0, 0]} attach="background" />
                </Canvas>
            </Suspense>
        </>
    )
}

function CanvasBody() {
    //no re-render here
    return (
        <>
            <Cam />
            <OrbitControls maxPolarAngle={1.5} target={[0, 0, 0]} enableZoom={false} makeDefault />

            <Ground />
            <Lighting />

            <CubeCamera frames={Infinity} resolution={256}>
                {(texture) => (
                    <>
                        <Environment map={texture} />
                        <Car />
                    </>
                )}
            </CubeCamera>

            <StarSky />
            <Planets />

            <Effects disableGamma>
                {/* threshhold = 1, nothing bloom, default */}
                <unrealBloomPass threshold={1} strength={5} radius={1} />
            </Effects>

            <AllImg />

        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Cam() {
    const camera = useRef()
    const { cameraPosition } = useContext(CameraContext)

    const [vec] = useState(() => new THREE.Vector3())
    useFrame(() => {
        camera.current.position.lerp(vec.set(cameraPosition.x, cameraPosition.y, cameraPosition.z), 0.05)
    })

    return (
        <>
            <PerspectiveCamera makeDefault fov={90} position={[0, 2.8, -48]} ref={camera} />
        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function StarSky() {
    const starRef = useRef()

    useFrame((state, delta) => {
        let t = delta * -0.1
        const star = starRef.current
        star.rotation.x += t
    })

    return (
        <>
            <Stars ref={starRef} radius={200} depth={100} count={5000} factor={4} saturation={0} fade speed={1} />
        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Ground() {

    const [normal, rough] = useLoader(TextureLoader, [
        process.env.PUBLIC_URL + './texture/aerial_asphalt_01_rough_2k.jpg',
        process.env.PUBLIC_URL + './texture/rough_plasterbrick_05_rough_2k.jpg',
    ])

    useEffect(() => {
        [normal, rough].forEach((texture) => {
            texture.wrapS = RepeatWrapping //紋理貼圖在水平方向上將如何包裹
            texture.wrapT = RepeatWrapping //紋理貼圖在垂直方向上將如何包裹
            texture.repeat.set(10, 10)
            rough.encoding = LinearEncoding //LinearEncoding默認值
        })
    }, [normal, rough])

    useFrame((state, delta) => {
        let elapsed = state.clock.getElapsedTime()
        let t = elapsed * -0.2
        normal.offset.set(0, t % 1)
        rough.offset.set(0, t % 1)
    })

    return (
        <>
            <mesh rotation-x={-Math.PI * 0.5} castShadow receiveShadow>
                <planeGeometry args={[100, 100, 1, 1]} />
                <MeshReflectorMaterial
                    normalMap={normal}
                    roughnessMap={rough}
                    dithering={true}
                    color={[0.01, 0.01, 0.01]}
                    roughness={1}
                    blur={[999, 999]}
                    mixBlur={99} //模糊強度
                    mixStrength={99} //反射強度
                    mixContrast={1} //反射對比度
                    resolution={1024} //分辨率
                    mirror={0}
                    depthScale={0.01}
                    minDepthThreshold={0.9}
                    maxDepthThreshold={1}
                    depthToBlurRatioBias={0.25}
                    debug={0}
                    reflectorOffset={0.2}
                />
            </mesh>
        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Lighting() {
    const streetLight_0_ref = useRef()
    const streetLight_1_ref = useRef()

    /* useHelper(streetLight_0_ref, SpotLightHelper, '#ffe692')
    useHelper(streetLight_1_ref, SpotLightHelper, '#ffe692') */

    //https://encycolorpedia.com/ffe692
    //street light #ffe692

    const steetLightColor = '#ffe692'

    const { animation } = useContext(AnimationContext)

    return (
        <>
            <ambientLight intensity={(animation === 'spiral' ? 1 : 0.01)} />

            <spotLight
                color={steetLightColor}
                intensity={1.5} //光照強度
                angle={0.7} //光線散射角度，最大為Math.PI/2
                penumbra={0.5} //聚光錐的半影衰減百分比。在0和1之間的值。默認為0。
                position={[5, 10, 0]}
                castShadow
                ref={streetLight_0_ref}
            />

            <spotLight
                color={steetLightColor}
                intensity={2}
                angle={0.7}
                penumbra={0.5}
                position={[-5, 10, 0]}
                castShadow
                ref={streetLight_1_ref}
            />
        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Car() {
    const gltf = useLoader(
        GLTFLoader,
        process.env.PUBLIC_URL + './modal/tesla/scene.gltf'
    )

    useEffect(() => {
        gltf.scene.scale.set(0.01, 0.01, 0.01)
        gltf.scene.position.set(0, 0.75, 0)
        gltf.scene.rotation.set(0, Math.PI, 0)
        gltf.scene.traverse((object) => {
            if (object instanceof THREE.Mesh) { //check callback is a mesh
                object.castShadow = true
                object.receiveShadow = true
                object.material.envMapIntensity = 10
            }
        })
    }, [gltf])

    const carRef = useRef()

    useFrame((state, delta) => {
        let elapsed = state.clock.getElapsedTime()
        //components show at children x 4 
        let frontWheel = gltf.scene.children[0].children[0].children[0].children[0].children[7].children[0]
        let backWheel = gltf.scene.children[0].children[0].children[0].children[0].children[7].children[1]
        let speed = -2 * elapsed
        //Wheel parts
        frontWheel.children[0].rotation.x = speed
        frontWheel.children[1].rotation.x = speed
        frontWheel.children[2].rotation.x = speed
        frontWheel.children[3].rotation.x = speed
        frontWheel.children[4].rotation.x = speed
        frontWheel.children[5].rotation.x = speed
        frontWheel.children[6].rotation.x = speed

        backWheel.children[0].rotation.x = speed
        backWheel.children[1].rotation.x = speed
        backWheel.children[2].rotation.x = speed
        backWheel.children[3].rotation.x = speed
        backWheel.children[4].rotation.x = speed
        backWheel.children[5].rotation.x = speed
        backWheel.children[6].rotation.x = speed

        updateCarLightStatus()
    })

    const [carLightOn, setCarLightON] = useState(true)

    const handleOnClick = () => {
        setCarLightON(carLightOn => !carLightOn)
    }

    function handleCarLightBloom(carparts) {
        if (carparts === null) return
        carparts.material.toneMapped = false
        carparts.material.color.r = 1000
        carparts.material.color.g = 1000
        carparts.material.color.b = 1000
    }

    function handleCarLightNotBloom(carparts) {
        if (carparts === null) return
        carparts.material.toneMapped = true
        carparts.material.color.r = 1
        carparts.material.color.g = 1
        carparts.material.color.b = 1
    }

    //useMemo
    const chrome_Lights_head_l_right_front_light_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'chrome_Lights_head_l_right_front_light_0')
    }, [gltf.scene])

    const chrome_Lights_head_l_left_front_light_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'chrome_Lights_head_l_left_front_light_0')
    }, [gltf.scene])

    const foglights_r_foglight_r_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'foglights_r_foglight_r_0')
    }, [gltf.scene])

    const foglights_l_foglight_l_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'foglights_l_foglight_l_0')
    }, [gltf.scene])

    const turn_indicat_r_indicator_rf_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'turn_indicat_r_indicator_rf_0')
    }, [gltf.scene])

    const turn_indicat_l_indicator_lf_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'turn_indicat_l_indicator_lf_0')
    }, [gltf.scene])

    const rear_lightsl_left_rear_light_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'rear_lightsl_left_rear_light_0')
    }, [gltf.scene])

    const rear_lightsr_right_rear_light_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'rear_lightsr_right_rear_light_0')
    }, [gltf.scene])

    const pantulans_pantulans0_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'pantulans_pantulans0_0')
    }, [gltf.scene])

    const light_turn_rr_boot_indicator_rr_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'light_turn_rr_boot_indicator_rr_0')
    }, [gltf.scene])

    const light_breake_breaklight_l_0 = useMemo(() => {
        return findKeyWithName(gltf.scene, 'light_breake_breaklight_l_0')
    }, [gltf.scene])

    function updateCarLightStatus() {
        const carHeadLightRight = carHeadLightRightRef.current
        const carHeadLightLeft = carHeadLightLeftRef.current

        if (carLightOn === false) {
            handleCarLightNotBloom(chrome_Lights_head_l_right_front_light_0)
            handleCarLightNotBloom(chrome_Lights_head_l_left_front_light_0)
            handleCarLightNotBloom(foglights_r_foglight_r_0)
            handleCarLightNotBloom(foglights_l_foglight_l_0)
            handleCarLightNotBloom(turn_indicat_r_indicator_rf_0)
            handleCarLightNotBloom(turn_indicat_l_indicator_lf_0)
            handleCarLightNotBloom(rear_lightsl_left_rear_light_0)
            handleCarLightNotBloom(rear_lightsr_right_rear_light_0)
            handleCarLightNotBloom(pantulans_pantulans0_0)
            handleCarLightNotBloom(light_turn_rr_boot_indicator_rr_0)
            handleCarLightNotBloom(light_breake_breaklight_l_0)

            //spotlight off
            carHeadLightRight.intensity = 0
            carHeadLightLeft.intensity = 0
        }
        else {
            handleCarLightBloom(chrome_Lights_head_l_right_front_light_0)
            handleCarLightBloom(chrome_Lights_head_l_left_front_light_0)
            handleCarLightBloom(foglights_r_foglight_r_0)
            handleCarLightBloom(foglights_l_foglight_l_0)
            handleCarLightBloom(turn_indicat_r_indicator_rf_0)
            handleCarLightBloom(turn_indicat_l_indicator_lf_0)
            handleCarLightBloom(rear_lightsl_left_rear_light_0)
            handleCarLightBloom(rear_lightsr_right_rear_light_0)
            handleCarLightBloom(pantulans_pantulans0_0)
            handleCarLightBloom(light_turn_rr_boot_indicator_rr_0)
            handleCarLightBloom(light_breake_breaklight_l_0)

            //spotlight on
            carHeadLightRight.intensity = 50
            carHeadLightLeft.intensity = 50

        }
    }

    const carHeadLightLeftRef = useRef()
    /* useHelper(carHeadLightLeftRef, SpotLightHelper, 'white') */

    const carHeadLightRightRef = useRef()
    /* useHelper(carHeadLightRightRef, SpotLightHelper, 'white') */

    useEffect(() => {
        const carHeadLightRight = carHeadLightRightRef.current
        carHeadLightRight.target = headLight_Right_TargetRef.current

        const carHeadLightLeft = carHeadLightLeftRef.current
        carHeadLightLeft.target = headLight_Left_TargetRef.current
    }, [])

    const headLight_Right_TargetRef = useRef()
    const headLight_Left_TargetRef = useRef()

    return (
        <>
            <primitive object={gltf.scene} ref={carRef} onClick={handleOnClick} />

            <mesh
                position={[-3, -13.5, 48]}
                ref={headLight_Right_TargetRef}
            >
                <boxGeometry args={[0.1, 0.1, 0.1]} />
                <meshStandardMaterial color={[0, 0, 0]} opacity={0} />
            </mesh>

            <mesh
                position={[3, -13.5, 48]}
                ref={headLight_Left_TargetRef}
            >
                <boxGeometry args={[0.1, 0.1, 0.1]} />
                <meshStandardMaterial color={[0, 0, 0]} opacity={0} />
            </mesh>


            <spotLight
                color={[1, 1, 1]}
                intensity={50} //光照強度
                angle={(Math.PI / 2) * 0.18} //光線散射角度，最大為Math.PI/2
                penumbra={0.5} //聚光錐的半影衰減百分比。在0和1之間的值。默認為0。
                position={[-0.8, 0.75, 1.8]}
                castShadow
                ref={carHeadLightRightRef}
            />

            <spotLight
                color={[1, 1, 1]}
                intensity={50} //光照強度
                angle={(Math.PI / 2) * 0.18} //光線散射角度，最大為Math.PI/2
                penumbra={0.5} //聚光錐的半影衰減百分比。在0和1之間的值。默認為0。
                position={[0.8, 0.75, 1.8]}
                castShadow
                ref={carHeadLightLeftRef}
            />

        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const findKeyWithName = (data, name) => {

    return data.children.reduce((res, d) => {

        if (res) {
            return res
        }
        if (d.name === name) {
            return d
        }

        return findKeyWithName(d, name)

    }, undefined)

}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Planets() {
    //https://svs.gsfc.nasa.gov/cgi-bin/details.cgi?aid=4720
    const moonRef = useRef()
    const sunLightRef = useRef()

    const [normal] = useLoader(TextureLoader, [
        process.env.PUBLIC_URL + './texture/lroc_color_poles_2k.jpg'
    ])

    useEffect(() => {
        normal.wrapS = RepeatWrapping //紋理貼圖在水平方向上將如何包裹
        normal.wrapT = RepeatWrapping //紋理貼圖在垂直方向上將如何包裹
        normal.encoding = LinearEncoding //LinearEncoding默認值

        moonRef.current.material.color.r = 10
        moonRef.current.material.color.g = 10
        moonRef.current.material.color.b = 10
    }, [normal])

    useFrame((state) => {
        let elapsed = state.clock.getElapsedTime()
        let t = elapsed / 2

        const moon = moonRef.current
        moon.rotation.x += 0.02

        moon.position.x = Math.cos(t) * 500
        moon.position.z = Math.sin(t) * 500

    })

    return (
        <>
            <mesh castShadow receiveShadow position={[500, 50, 500]} ref={moonRef}>
                <sphereBufferGeometry args={[25, 64, 64]} />
                <meshStandardMaterial map={normal} envMapIntensity={1} toneMapped={false} />
            </mesh>

            <directionalLight position={[0, 2000, 0]} color={[1, 1, 1]} intensity={0.25} ref={sunLightRef} target={moonRef.current} />
        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function Img({ image, nos, imageLink }) {

    const [active, setActive] = useState(0)

    const handleOnEnter = () => {
        //return 0 || 1
        setActive(Number(!active))
    }

    const handleOnLeave = () => {
        setActive(Number(!active))
    }

    const [geoPosition] = useState(getPosition(nos))

    const imgRef = useRef()

    const { animation } = useContext(AnimationContext)

    useFrame((state, delta) => {
        let mesh = imgRef.current
        let elapsed = state.clock.getElapsedTime()
        let t = elapsed
        let distanceBasedOnPI = Math.PI / 7.5 * nos
        //mode: straight, circle
        switch (animation) {
            case 'circle':
                //15 nos. image, Math.PI * 2 / 15
                mesh.rotation.x = Math.PI * 0.2
                mesh.position.x = Math.cos(t + distanceBasedOnPI) * 7.5
                mesh.position.z = Math.sin(t + distanceBasedOnPI) * 7.5 + 10
                mesh.position.y = 2.8
                break
            case 'spiral':
                //bigger nos at bottom
                //highest level y=15/2 +1
                let spirialSize = 15 / 15 * nos
                mesh.rotation.x = Math.PI * 0.35
                mesh.position.x = Math.cos(t + distanceBasedOnPI) * (spirialSize + 5)
                mesh.position.z = Math.sin(t + distanceBasedOnPI) * (spirialSize + 5)
                mesh.position.y = 8.5 - nos / 2
                break
            default:
                mesh.position.z -= 0.05
                if (mesh.position.z < -26.5) {
                    //3.5 distance per img, 3.5 * 15nos. = 52.5, 52.5/2 = 26.5
                    mesh.position.z = 26.5
                }
                break
        }
    })

    function getPosition(num) {
        //get left and right, x = -1 || 1
        let x = num % 2 * 2 - 1
        //size = 1.92 & 1.08, 2 times = 3.84 & 2.16 
        let vector = new Vector3(x * 3.84, 2.16, num * 3.5 + 3.5)
        return vector
    }

    const { spring } = useSpring({
        spring: active,
        config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 },
    })

    const scale = spring.to([0, 1], [1, 2])

    const [zoom, setZoom] = useState(false)

    const handleOnZoom = () => {
        setZoom(zoom => !zoom)
    }

    return (
        <>
            <a.mesh
                rotation-x={Math.PI * 0.2}
                position={geoPosition}
                ref={imgRef}
                onPointerEnter={handleOnEnter}
                onPointerLeave={handleOnLeave}
                onClick={handleOnZoom}
                scale-x={scale}
                scale-y={scale}
            >
                {/*1080p = 1920*1080, set size to 1.92 & 1.08 */}
                <planeGeometry args={[1.92, 1.08, 1, 1]} />
                <meshStandardMaterial map={image} envMapIntensity={1} toneMapped={true} side={THREE.DoubleSide} />
            </a.mesh>

            {zoom && <ImageZoom imageSource={imageLink} onClick={handleOnZoom} />}
        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function AllImg() {

    const [img_0, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_12, img_13, img_14] = useLoader(TextureLoader,
        //create new array
        Array(15).fill().map((element, i) => (
            process.env.PUBLIC_URL + imgArray[i]
        ))
    )

    return (
        <>
            {[img_0, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_12, img_13, img_14].map((element, i) => (
                <Img image={element} nos={i} imageLink={imgArray[i]} key={i} />
            ))}
        </>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function ImageZoom({ imageSource, onClick }) {

    return (
        <Html fullscreen>
            <div className='w-full h-full m-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center'
                onClick={onClick}>
                <div className='w-full md:w-1/2'>
                    <motion.img src={process.env.PUBLIC_URL + imageSource} alt='error' className='object-contain'
                        initial={{ opacity: 0.1 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }} />
                </div>
            </div>
        </Html>
    )
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const imgArray = [
    './img/743bf2ab-4c87-48e6-90b9-29b5cf813cb1.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG',
    './img/IMG_7254.JPG'
]
