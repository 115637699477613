import { BrowserRouter, Route, Routes } from "react-router-dom"
import Main from "./Main"
import { AnimationContext, CameraContext } from './Context'
import { useState } from "react";

function App() {

  const [animation, setAnimation] = useState('straight')

  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 2.8, z: -4.8 })

  return (
    <BrowserRouter>
      <CameraContext.Provider value={{ cameraPosition, setCameraPosition }}>
        <AnimationContext.Provider value={{ animation, setAnimation }}>
          <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/*" element={<p>404</p>}></Route>
          </Routes>
        </AnimationContext.Provider>
      </CameraContext.Provider>
    </BrowserRouter>
  );
}

export default App;
